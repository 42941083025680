import { usePaystackPayment } from 'react-paystack';
import Swal from 'sweetalert2';
// import { useLocation } from "react-router-dom";
const config = JSON.parse(sessionStorage.getItem('config'));
const OnSuccess = () => {
    sessionStorage.setItem('copayath', 'athacademypay');
    Swal.fire({
        title: 'Payment Successful!',
        text: 'Please proceed for confirmation!',
        icon: 'success',
        confirmButtonColor: '#198754',
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Proceed!',
    }).then((result) => {
        if(result.isConfirmed){
            window.location.href = "/confirm";
        }
    });
};
  const onClose = () => {
    Swal.fire({
        title: 'The payment was cancelled!',
        text: "Would you like to continue your with payment?",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#198754',
        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Yes!',
        cancelButtonText: "<i class='fa fa-thumbs-up'></i> I'll visit the hub!",
    }).then((result) => {
        if(result.isConfirmed){
            window.location.href = "/pay";
        }else{
            window.location.href = "/contact";
        }
    });
  };
const Payment = () => {
    const initializePayment = usePaystackPayment(config);
    initializePayment(OnSuccess,onClose);
    return (
        <div></div>
    );
}

export default Payment;