import { useState } from 'react';
import { getCourses, getCourseName} from '../data/course-data';
// import Pay from './pay';
import Swal from 'sweetalert2';
import $  from 'jquery';
// const testStyle = {
//     color: 'white',
//     backgroundColor: 'black'
// }
async function register(credentials) {
    // https://server.abiatechhub.com/register
    //http://localhost:3001/register
	return fetch('https://server.abiatechhub.com/register', {
	  method: 'POST',
	  headers: {
		'Content-Type': 'application/json'
	  },
	  body: JSON.stringify(credentials)
	}).then(data => data.json()).catch(err => Swal.fire({
            title: 'Something went wrong!',
            icon: 'error',
            confirmButtonText: 'Try again',
    }).then((result) => {
        if(result.isConfirmed){
            $('#regbut').html('Register');
        }
    })
    );
   }
  
const Register = () => {
    const [firstname, setFirstName] = useState();
	const [lastname, setLastName] = useState();
	const [phoneno, setPhoneNo] = useState();
    const [email, setEmail] = useState();
    const [residence, setResidence] = useState();
    const [course, setCourse] = useState();
	const handleSubmit = async e => {
        const courseName = getCourseName(course);
		e.preventDefault();
            $('#regbut').html('Please wait <i class="fa fa-spinner-third fa-spin fa-1x fa-pulse ms-3"></i>');
            const response = await register({
                firstname,
                lastname,
                phoneno,
                email,
                residence,
                course
            });
            if(response){
            if(response.status === 200){
                $('#regbut').html('Register');
                Swal.fire({
                    title: response.message,
                    text: "Would you like to proceed to payment?",
                    icon: 'success',
                    showCancelButton: true,
                    confirmButtonColor: '#198754',
                    confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok!',
                    cancelButtonText: "<i class='fa fa-thumbs-up'></i> I'll visit the hub!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                           const config =  {
                                reference: "ATHACADEMY"+(new Date()).getTime().toString(),
                                email: response.userEmail,
                                amount: courseName.price*100,
                                publicKey: 'pk_live_4dbf686ee1fcdf6cff24e049f6697e6a3ec53e91',
                            }
                            //pk_live_4dbf686ee1fcdf6cff24e049f6697e6a3ec53e91
                        sessionStorage.setItem('config',JSON.stringify(config));
                        window.location.href = "/pay";
                    }else{
                        window.location.href = "/contact";
                    }
                  })
                // $('.alert-danger').hide();
                // $('.alert-success').html(response.message)
                // $('.alert-success').show();
            }else if(response.status === 400){
                Swal.fire({
                    title: response.message,
                    icon: 'error',
                    confirmButtonText: 'Try again',
                  }).then((result) => {
                    if(result.isConfirmed){
                        $('#regbut').html('Register');
                    }
                  });
            }
        }
	  }
    let courses = getCourses();
    return(
        <section className="woocommerce single page-wrapper">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-md-8 col-xl-7">
                
                <div className="signup-form">
                    <div className="form-header">
                        <h2 className="font-weight-bold mb-3">Sign Up For a Course</h2>
                    </div>

                    <form method="post" onSubmit={handleSubmit} className="woocommerce-form woocommerce-form-register register">

                        <div className="row">
                            <div className="col-xl-6">
                                <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                    <label>First Name&nbsp;<span className="required">*</span></label>
                                    <input type="text" className="form-control" name="first-name" placeholder="First Name" onChange={e => setFirstName(e.target.value)} required/>
                                </p>
                            </div>
                            <div className="col-xl-6">
                                <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                    <label>Last Name&nbsp;<span className="required">*</span></label>
                                    <input type="text" className="form-control" name="last-name"  placeholder="Last Name" onChange={e => setLastName(e.target.value)} required/>
                                </p>
                            </div>
                            <div className="col-xl-6">
                                <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                    <label>Phone Number&nbsp;<span className="required">*</span></label>
                                    <input type="number" className="form-control" name="phoneno" defaultValue="" placeholder="Your Phone No." onChange={e => setPhoneNo(e.target.value)} required/>
                                </p>
                            </div>
                            <div className="col-xl-6">
                                <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                    <label>Email&nbsp;<span className="required">*</span></label>
                                    <input type="email" className="form-control" name="email" defaultValue="" placeholder="Your Email" onChange={e => setEmail(e.target.value)} required/>
                                </p>
                            </div>
                            <div className="col-xl-6">
                                <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                    <label>Residence&nbsp;<span className="required">*</span></label>
                                    <input type="text" className="form-control" name="residence" defaultValue="" placeholder="LGA, State" onChange={e => setResidence(e.target.value)} required/>
                                </p>
                            </div>
                            <div className="col-xl-6">
                            <p className="woocommerce-form-row woocommerce-form-row--wide form-row form-row-wide">
                                    <label>Course&nbsp;<span className="required">*</span></label>
                                    <select name="course" id="course" className="form-control"onChange={e => setCourse(e.target.value)} required>
                                    <option selected disabled>Select course</option>
                                        {courses.map(
                                            course => (
                                                <option key={course.id} defaultValue={course.name}>{course.name}</option>
                                            )
                                        )}
                                    </select>
                            </p>
                            </div>

                            <div className="col-xl-12">
                                <div className="d-flex align-items-center justify-content-between py-2">
                                    <p className="form-row">
                                        <label className="woocommerce-form__label woocommerce-form__label-for-checkbox woocommerce-form-login__policy">
                                            <input className="woocommerce-form__input woocommerce-form__input-checkbox" name="policy" type="checkbox" id="policy" defaultValue="forever"  required/> <span>Accept the Terms and Privacy Policy</span>
                                        </label>
                                    </p>
                               </div>
                            </div>
                        </div>
                        <p className="woocommerce-FormRow form-row">
                            <button type="submit" className="woocommerce-button button" name="register" defaultValue="Register" id="regbut">Register </button>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
    )
}

export default Register;