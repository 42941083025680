import Logowhite from '../assets/images/athwhite.png';
import {getCourses} from '../data/course-data';
import {Link} from 'react-router-dom';
const Footer = () => {
	let Acourses = getCourses();
    let num = 4;
    let courses = Acourses.slice(0, num);
    return(
        <div>
			<section className="footer">
				<div className="footer-mid">
					<div className="container">
						<div className="row">
							<div className="col-xl-3 me-auto col-sm-8">
								<div className="footer-logo mb-3">
									<img src={Logowhite} alt="" className="img-fluid" height={95} width={140}/>
								</div>
								<div className="widget footer-widget mb-5 mb-lg-0">				
									<p>Where learning happens</p>
								</div>
							</div>

							<div className="col-xl-2 col-sm-4">
								<div className="footer-widget mb-5 mb-xl-0">
									<h5 className="widget-title">quick links</h5>
									<ul className="list-unstyled footer-links">
										<li><Link to="courses">Courses</Link></li>
										<li><Link to="contact">Contact us</Link></li>
									</ul>
								</div>
							</div>

							<div className="col-xl-2 col-sm-4">
								<div className="footer-widget mb-5 mb-xl-0">
									<h5 className="widget-title "><Link to="courses">Courses</Link></h5>
									<ul className="list-unstyled footer-links">
										{ courses.map(
											course => (
												<Link key={course.id} to={`/courses/${course.slug}`}><li >{course.slug}</li></Link>
											)
										)}
									</ul>
								</div>
							</div>

							<div className="col-xl-2 col-sm-4">
								<div className="footer-widget mb-5 mb-xl-0">
									<h5 className="widget-title">Address</h5>
									<ul className="list-unstyled footer-links">
										<li><h6 className="text-white">Phone</h6><Link to="tel:+234 805 274 9600">+234 805 274 9600</Link></li>
										<li><h6 className="text-white">Email</h6><Link to="mailto:hello@abiatechhub.com">hello@abiatechhub.com</Link></li>
									</ul>
									<div className="footer-socials mt-4">
										<Link to="#"><i className="fab fa-facebook-f"></i></Link>
										<Link to="#"><i className="fab fa-twitter"></i></Link>
										<Link to="#"><i className="fab fa-linkedin-in"></i></Link>
									</div>
								</div>
							</div>
							<div className="col-xl-2 col-sm-4">
								<div className="footer-widget mb-5 mb-xl-0">
									
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="footer-btm">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-xl-6 col-sm-12 col-lg-6">
								<p className="mb-0 copyright text-sm-center text-lg-start">© {new Date().getFullYear()} ATH Academy All rights reserved by <a href="https://abiatechhub.com" rel="nofollow">Abia Tech Hub</a> </p>
							</div>
							<div className="col-xl-6 col-sm-12 col-lg-6">
								
							</div>
						</div>
					</div>
				</div>

				<div className="fixed-btm-top">
					<Link to="#top-header" className="js-scroll-trigger scroll-to-top"><i className="fa fa-angle-up"></i></Link>
				</div>
	
			</section>
        </div>
    )
}

export default Footer;