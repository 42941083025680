// import { useState } from 'react';
import Swal from 'sweetalert2';
 const Contact = () => {
    const handleSubmit = async e => {
		e.preventDefault();
                Swal.fire({
                    title: 'Something went wrong!',
                    text: 'Please try again later or contact us directly.',
                    icon: 'error',
                    confirmButtonColor: '#198754',
                    confirmButtonText: 'Ok',
                  })
	  }
     return(
         <div>
             <section className="page-header">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-8 col-xl-8">
          <div className="title-block">
            <h1>Contact Us</h1>
          </div>
        </div>
      </div>
    </div>
</section>
        <section className="contact section-padding">
    <div className="container">
        <div className="row justify-content-between">
            <div className="col-xl-4 col-lg-5">
               <div className="contact-info-wrapper mb-5 mb-lg-0">
                   <h3>what's your story? 
                       <span>Get in touch</span>
                   </h3>
                   <p></p>

                    <div className="contact-item">
                        <i className="fa fa-envelope"></i>
                        <h5>hello@abiatechhub.com</h5>
                    </div>

                    <div className="contact-item">
                        <i className="fa fa-phone-alt"></i>
                        <h5>+234 805 274 9600</h5>
                    </div>

                    <div className="contact-item">
                        <i className="fa fa-map-marker"></i>
                        <h5>No. 6 Warri street, Umuahia, Abia state</h5>
                    </div>
               </div>
            </div>

            <div className="col-xl-7 col-lg-6">
                <form className="contact__form form-row " method="post" action="mail.php" id="contactForm" onSubmit={handleSubmit}>
                    <div className="row">
                       <div className="col-12">
                           <div className="alert alert-success contact__msg" style={{display: "none"}} role="alert">
                               Your message was sent successfully.
                           </div>
                       </div>
                   </div>

                   <div className="row">
                       <div className="col-lg-6">
                           <div className="form-group">
                               <input type="text" id="name" name="name" className="form-control" placeholder="Your Name" required/>
                           </div>
                       </div>
                       
                       <div className="col-lg-6">
                           <div className="form-group">
                               <input type="text" name="email" id="email" className="form-control" placeholder="Email Address" required/>
                           </div>
                       </div>
                       <div className="col-lg-12">
                           <div className="form-group">
                               <input type="text" name="subject" id="subject" className="form-control" placeholder="Subject" required />
                           </div>
                       </div>
                       
                       <div className="col-lg-12">
                           <div className="form-group">
                               <textarea id="message" name="message" cols="30" rows="6" className="form-control" placeholder="Your Message"  required></textarea>    
                           </div>
                       </div>
                   </div>

                   <div className="col-lg-12">
                       <div className="text-center">
                           <button className="btn btn-main w-100 rounded" type="submit">Send Message</button>
                       </div>
                   </div>
               </form> 
            </div>
        </div>
    </div>
</section>
<section className="map">
    <div id="map"></div>
 </section>
 </div>
     )
 }

 export default Contact;