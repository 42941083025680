import webDev from '../assets/images/webdev.jpg';
import dMarket from '../assets/images/dmarket.jpg';
import dliteracy from '../assets/images/dliteracy.jpg';
import ui from '../assets/images/ui.jpg';
import gdesign from '../assets/images/gdesign.jpg';
import it from '../assets/images/it.jpg';
import se from '../assets/images/se.jpg';
import mapp from '../assets/images/mapp.jpg';
import cp from '../assets/images/cp.jpg'
import db from '../assets/images/911.jpg';
import csecurity from '../assets/images/csecurity.jpg';
import ai from '../assets/images/ai.jpg';
import pmanage from '../assets/images/pmanage.jpg';
import pmanagement from '../assets/images/pmanagement.jpg';
import pvgraphy from '../assets/images/pvgrapghy.jpg';
import blockchain from '../assets/images/442.jpg';
import sa from '../assets/images/sa.jpg';
import entre from '../assets/images/entre.jpg';
import ad from '../assets/images/ad.jpg';
let courses = [ 
  {
    id: 1,
    name: "Fundamental Digital Literacy",
    slug: "fundamental_digital_literacy",
    duration: "6 weeks",
    fee: "N45,000",
    curriculum: ["Introduction to Computer", "Computer Appreciation", "Fundamentals of PC and operations", "Typing Tutorial", "Word Processing"],
    img: dliteracy,
    description: "This basic course introduces the digital world and the skills necessary to excel in society. It introduces digital technologies like the internet, social media and mobile devices and how to use them to find, create, evaluate and communicate information.",
    price: 45000,
  },
  {
    id: 2,
    name: "Fullstack Web development",
    slug: "fullstack_web_development",
    duration: "12 weeks",
    fee: "N170,000",
    curriculum: ["Fundamentals of Web Development", "Hypertext Markup Language", "Cascading Style Sheet", "JavaScript", "Github", "React JS", "Frontend Project", "web server", "Python", "PHP", "SQL", "MONGODB", "Tooling and Setup", "Fullstack Project and Capstone", "INTERNSHIP"],
    img: webDev,
    description: "Being one of the most sought-after specialties, this course teaches how to build fully functional and responsive websites and web applications using different programming languages. It explores the fundamentals of web development up to the application of advanced web development frameworks.",
    price: 170000,
    downloadable: true,
    pdfUrl: "/Pdfurls/Full_Stack_Development.pdf",
  },
  {
    id: 3,
    name: "Advanced Graphic Design",
    slug: "advanced_graphic_design",
    duration: "12 weeks",
    fee: "N140,000",
    curriculum: ["Graphics Design Basics", "Basic Rules", "Color Theory", "Typography", "Layout", "Composition", "Branding", "Logo Design", "Modern Trends", "The Business of Design"],
    img: gdesign,
    description: "Learn how to create visual content that communicates to your audience and presents aesthetically pleasing designs in this intensive course using powerful design tools like Adobe Photoshop and Adobe Illustrator.",
    price: 140000,
  },
  {
    id: 4,
    name: "UI/UX Design",
    slug: "UI_UX_design",
    duration: "12 weeks",
    fee: "N130,000",
    curriculum: ["Introduction to UI Design", "Introduction to UX Design", "User Research", "Information Architecture", "Interaction Design", "Prototyping Tools", "Visual Design Principles", "Portfolio Development", "UI/UX Design Capstone"],
    img: ui,
    description: "This 2-in-in course teaches people how to build interfaces and visuals that aids user interaction with a product. It also extends to assessing or brainstorming over the user's interaction to further improve the product.",
    price: 130000,
    downloadable: true,
    pdfUrl: "/Pdfurls/UI_UX Design",
  },
  {
    id: 5,
    name: "Data Analysis / Visualization",
    slug: "data_analysis_and_visualization",
    duration: "12 weeks",
    fee: "N150,000",
    curriculum: ["Introduction to Data Analytics", "Statistics", "Understanding Data", "Microsoft Excel for data analytics", "SQL for Data Analytics", "Power BI for Business Intelligence", "Python for Data Analytics", "Capstone Project and Internship"],
    img: ui,
    description: "This curriculum covers 3 months of teaching guidelines, 3 days per week, and 1 month of internship. It runs from basic and intermediate classes to advanced classes.",
    price: 150000,
    downloadable: true,
    pdfUrl: "/Pdfurls/Data_Analysis_Visualiuzation",
  },
  {
    id: 6,
    name: "Digital Marketing",
    slug: "digital_marketing",
    duration: "12 weeks",
    fee: "N120,000",
    curriculum: ["Introduction", "Search Engine Optimization", "Google Ads – Search & Display Advertising Campaigns", "Google My Business", "Content Marketing", "Social Media Marketing", "Social Media Advertising", "Google Analytics"],
    img: dMarket,
    description: "This specialty surrounds using the internet and digital technologies to communicate to potential customers of a brand, product or service.",
    price: 120000,
  },
  {
    id: 7,
    name: "Diploma in Information Technology",
    slug: "diploma_in_information_technology",
    duration: "10 weeks",
    fee: "N100,000",
    curriculum: ["Microsoft Office Suite", "Desktop Publishing", "Basic Graphics (Corel Draw and Adobe Photoshop)", "Office Automation", "Internet and E-mail", "Blogging"],
    img: it,
    description: "This diploma-level course intends to provide students with a wide range of technical and technological knowledge. It teaches how to use use computers to manipulate data in various ways.",
    price: 100000,
  },
  {
    id: 8,
    name: "Software Engineering",
    slug: "software_engineering",
    duration: "12 weeks",
    fee: "N200,000",
    curriculum: ["General Introduction", "Software Evolution", "Software Development Lifecycles", "Software Project Management and Methodologies", "Software Design and Analysis tools", "Software Implementation Software Testing and Maintenance", "DevOps CI/CD"],
    img: se,
    description: "In this course, students learn how the concepts and processes of designing, developing and maintaining functional software.",
    price: 200000,
  },
  {
    id: 9,
    name: "Mobile App Development",
    slug: "mobile_app_development",
    duration: "12 weeks",
    fee: "N180,000",
    curriculum: ["User Interactions", "Simple User Interface", "Dart", "Flutter", "React Native"],
    img: mapp,
    description: "Mobile App Development is an intensive course that guides through developing mobile apps for mobile devices",
    price: 180000,
  },
  {
    id: 10,
    name: "Computer Programming",
    slug: "computer_programming",
    duration: "12 weeks",
    fee: "N110,000",
    curriculum: ["Introduction", "Data and Control Structures", "Analytical Thinking/Problem solving", "Algorithm development and design patterns", "Language Selection and Specialization"],
    img: cp,
    description: "This course teaches students how to write codes that instruct computers on what tasks to perform and how to perform them. This also covers the concepts of Data Structures, Algorithms and Design patterns.",
    price: 110000,
  },
  {
    id: 11,
    name: "Database Management",
    slug: "database_management",
    duration: "6 weeks",
    fee: "N100,000",
    curriculum: ["Introduction", "Fundamental Database Concepts", "SQL", "Oracle", "MongoDB"],
    img: db,
    description: "Explore the creation, organization, storage, retrieval and manipulation of data using Database Management Systems in this intensive course. ",
    price: 100000,
  },
  {
    id: 12,
    name: "Cyber Security",
    slug: "cyber_security",
    duration: "12 weeks",
    fee: "N180,000",
    curriculum: ["Cybersecurity Fundamentals", "Basic Networking Concepts", "Operating Systems and Security", "Introduction to Python", "Cybersecurity Frameworks and Standards", "Information Security Policies and Procedures", "Threats and Vulnerabilities", "Network Security", "Access Control and Authentication", "Security Monitoring and Incident Response", "Encryption and Cryptography", "Web Security", "Ethical Hacking and Penetration Testing", "Social Engineering and Phishing", "Exploitation and Post-Exploitation", "Cybersecurity Tools", "Capstone: Cybersecurity Hands on"],
    img: csecurity,
    description: "This course is designed to guide participants on how to protect data and systems against malicious activities. It also teaches how to analyze and detect vulnerabilities in an application or system that can be exploited by an attacker and counter such attacks.",
    price: 180000,
    downloadable: true,
    pdfUrl: "/Pdfurls/Cyber_Security",
  },
  {
    id: 13,
    name: "Artificial Intelligence and Machine Learning",
    slug: "artificial_intelligence_and_machine_learning",
    duration: "12 weeks",
    fee: "N250,000",
    curriculum: ["History and foundations of AI", "Problem-solving by Search", "Introduction to Machine Learning", "Natural Language Processing", "Knowledge and Reasoning"],
    img: ai,
    description: "Artificial Intelligence uses sets of algorithms to train machines to learn from and mimic human intelligence. This course covers the concepts of AI and Machine Learning.",
    price: 250000,
  },
  {
    id: 14,
    name: "System Administration",
    slug: "system_administration",
    duration: "6 weeks",
    fee: "N120,000",
    curriculum: ["Research and Documentation Writing", "Installing and managing software", "Scripting and Intro to Automation", "Local and Network Security", "Protocols Powering the internet"],
    img: sa,
    description: "This field is responsible for managing the upkeep, configuration, and reliable operation of multi-user computer systems such as servers and databases. It also involves planning and properly responding to system outages and various other problems.    ",
    price: 120000,
  },
  {
    id: 15,
    name: "Blockchain DApp Development",
    slug: "blockchain_development",
    duration: "12 weeks",
    fee: "N290,000",
    curriculum: ["Need for Blockchain", "Blockchain Development", "Understanding Different Programming Languages"],
    img: blockchain,
    description: "In this course you learn the processes of building decentralized applications with backend code running on a decentralized peer-to-peer network. ",
    price: 290000,
  },
  {
    id: 16,
    name: "Photography & Videography",
    slug: "photography_and_videography",
    duration: "12 weeks",
    fee: "N180,000",
    curriculum: ["Introduction", "Equipment handling", "Shot Composition", "Shutter speed, white balance and ISO", "Lighting equipment", "Sound Recording", "Different types of edits", "Color Correction", "Sound and image synchronization", "Script Writing"],
    img: pvgraphy,
    description: "This course is designed to bestow in depth  knowledge on basic camera functions, filters, flash and how images are affected by light.  We also teach fundamentals of videography, how to capture moving pictures and sound using digital video and audio equipment, coupled with lighting, composition, framing, and editing expertise.",
    price: 180000,
  },
  {
    id: 17,
    name: "Project Management",
    slug: "project_management",
    duration: "6 weeks",
    fee: "N100,000",
    curriculum: ["Introduction", "Planning Your Project", "Implementing Your Project", "Project Management Techniques", "Business and Financial Issues", "Career in Project Management"],
    img: pmanage,
    description: "In this course, you are trained on how to apply certain processes and methods, bestowed with skills and knowledge to achieve specific project objectives. We also equip you with excellent communication skills as Successful project managers should be detail-oriented and have excellent communication skills.",
    price: 100000,
  },
  {
    id: 18,
    name: "Architechural Design(2D/3D)",
    slug: "architechural_design",
    duration: "12 weeks",
    fee: "N180,000",
    curriculum: ["Understanding Architectural basics", "Creating basic interior scene", "Using utilities for efficient production", "Building a mini project"],
    img: ad,
    description: "This course is designed to Develop your basic design literacy and teaches basic methods of solving architectural  problems and skills in preparation for tackling complex design problems in architecture. Also in this course, students are introduced to a basic vocabulary of three-dimensional form making, space making and they learn to solve simple design problems systematically, with creativity and imagination.",
    price: 180000,
  },
  {
    id: 19,
    name: "Entrepreneurship Masterclass",
    slug: "entrepreneurship_masterclass",
    duration: "12 weeks",
    fee: "N170,000",
    curriculum: ["Turning ideas into products", "Figuring out the right business model", "Fundamentals of growth and sales", "Incorporating your company", "Everything you need to know about fundraising", "Becoming a leader", "The perfect business pitch", "Building great teams"],
    img: entre,
    description: "This class helps you develop the knowledge, skills and mindset to thrive in an entrepreneurial environment. In this course, you will learn essential business fields such as finance, sales, marketing, versatility. This entrepreneurship course  will teach you how to cultivate a growth-oriented mindset to thrive in business.",
    price: 170000,
  },
  {
    id: 20,
    name: "Product Management",
    slug: "product_management",
    duration: "6 weeks",
    fee: "N150,000",
    curriculum: ["Introduction to Product Management", "Understanding the Market", "Product Strategy and Vision", "Product Development and Execution", "Launching and Managing Products", "Business and Financial Aspects", "Career in Product Management"],
    img: pmanagement,
    description: "In this course, you will learn how to effectively oversee the lifecycle of a product, from ideation to market launch and beyond. You'll acquire skills in defining a product vision, conducting market research, managing stakeholders, and ensuring that the product meets customer needs. The course also focuses on developing leadership and strategic thinking, essential for a successful career in product management.",
    price: 150000,
  },
];
const getCourses = () => {
  return(
      courses
  )
}

const getCourse = (courseSlug) => {
  return courses.find(
    course => course.slug === courseSlug
  )
}
const getCourseName = (cslug) => {
  return courses.find(
    course => course.name === cslug
  )
}

// Update prices and fees by 30%
courses.forEach(course => {
  course.price = Math.round(course.price * 1.3); // Increase price by 30%
  course.fee = `N${course.price.toLocaleString()}`; // Update fee string
});

console.log(courses); // Verify the updated courses


  export {getCourses, getCourse, getCourseName};