import { Link } from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {getCourse, getCourses} from '../data/course-data';
const Course = () => {
    let { courseSlug } = useParams();
    let course = getCourse(courseSlug);
    let Acourses = getCourses();
    let num = 4;
    let courses = Acourses.slice(0, num);

    if (!course) {
        return <div>Course not found</div>;
    }
    return(
    <div>
        <section className="course-page-header">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-xl-8">
                        <div className="title-block">
                            <h1>{course.name}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="page-wrapper">
            <div className="tutori-course-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-xl-8">
                            <nav className="course-single-tabs learn-press-nav-tabs">
                                <div className="nav nav-tabs course-nav" id="nav-tab" role="tablist">
                                    <Link className="nav-item nav-link active" id="nav-home-tab" data-bs-toggle="tab" to="#nav-home" role="tab" aria-controls="nav-home-tab" aria-selected="true">Overview</Link>
                                </div>
                            </nav> 
                            <div className="tab-content tutori-course-content" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                    <div className="single-course-details ">
                                        <h4 className="course-title">Description</h4>
                                            <p>{course.description}</p>


                                        <div className="course-widget course-info">
                                            <h4 className="course-title">What You will Learn?</h4>
                                            <ul>
                                                {course.curriculum.map(
                                                    curriculum => (
                                                        <li>
                                                    <i className="fa fa-check"></i>
                                                        {curriculum}
                                                    </li>
                                                    )
                                                    
                                                )}
                                            </ul>
                                        </div>
                                        
                                        {course.downloadable && (
                                        <div className="buy-btn">
                                        <a
                                            href={course.pdfUrl}
                                            download
                                            rel="noopener noreferrer"
                                        ><button className="button button-enroll-course btn btn-main-2 rounded">
                                            <i className="fas fa-download"></i> Download syllabus 
                                        </button></a>
                                    </div>
                                    )}
                                    </div>
                                </div>

                    </div>  
                </div>
                <div className="col-lg-5 col-xl-4">
                    <div className="course-sidebar course-sidebar-2 mt-5 mt-lg-0">
                        <div className="course-widget course-details-info">
                            <div className="course-thumbnail">
                                <img src={course.img} alt="" className="img-fluid w-100" />
                            </div>

                            <div className="price-header">
                                <h2 className="course-price">{course.fee}<span></span></h2>
                            </div>

                            <ul className="course-sidebar-list">
                                <li>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span><i className="far fa-sliders-h"></i>Level</span>
                                        Beginnner
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span><i className="far fa-user"></i>Students</span>
                                        20
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span><i className="far fa-clock"></i>Duration</span>
                                        {course.duration}
                                    </div>
                                </li>
                                <li>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span><i className="far fa-globe"></i>Language</span>
                                        English
                                    </div>
                                </li>
                            </ul>
                            <div className="buy-btn">
                                <Link to='/register'><button className="button button-enroll-course btn btn-main-2 rounded">
                                    <i className="far fa-shopping-cart me-2"></i> Enroll Course 
                                </button></Link>
                            </div>
                        </div>

                        <div className="course-latest">
                        <h4 className="mb-4">Other Courses</h4>
                        <ul className="recent-posts course-popular">
                            {
                                courses.filter((item) => item.name != course.name).map(
                                    course => (
                                        <li key={course.slug}>
                                            <div className="widget-post-thumb">
                                                <Link to="#"><img src={course.img} alt="" className="img-fluid" /></Link>
                                            </div>
                                            <div className="widget-post-body">
                                                <h6> <Link to="#">{course.name}</Link></h6>
                                                <h5>{course.fee}</h5>
                                            </div>
                                        </li>
                                    )
                                )
                            }
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
    )
}

export default Course;