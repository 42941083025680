import React from 'react';
import Header from './includes/header';
import { BrowserRouter as Router, Route, Routes, Link, NavLink, Switch, Redirect, Prompt} from 'react-router-dom';
import Home from './components/home';
import Footer from './includes/footer';
import Courses from './components/courses';
import Course from './components/course';
import Register from './components/register';
import Contact from './components/contact';
import Payment from './components/payment';
import ConfirmPay from './components/confirmpay';
import './assets/vendors/bootstrap/bootstrap.css';
import './assets/vendors/awesome/css/fontawesome-all.min.css';
import './assets/vendors/flaticon/flaticon.css';
import './assets/vendors/magnific-popup/magnific-popup.css';
import './assets/vendors/animate-css/animate.css';
import './assets/vendors/animated-headline/animated-headline.css';
import './assets/vendors/owl/assets/owl.carousel.min.css';
import './assets/vendors/owl/assets/owl.theme.default.min.css';
import './assets/css/woocomerce.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/js/script.js';
function App() {
  return (
    <Router>
      <Routes>
          <Route path='/' element={<><Header /><Home /><Footer /></>} />
          <Route path='/home' element={<><Header /><Home /><Footer /></>} />
          <Route path='/courses' element={<><Header /><Courses /><Footer /></>} />
          <Route path='/*' element={<div>Not Found</div>} />
          <Route path='/register' element={<><Header /><Register /><Footer /></>} />
          <Route path='/courses/:courseSlug' element={<><Header /><Course /><Footer /></>} />
          <Route path='/contact' element={<><Header /><Contact /><Footer /></>} />
          <Route path='/pay' element={<Payment />} />
          <Route path='/confirm' element={<ConfirmPay />} />
      </Routes>
    </Router>
  );
}

export default App;
