import Swal from 'sweetalert2';
async function updatePayment(credentials) {
    return fetch('https://server.abiatechhub.com/confirm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body:  JSON.stringify(credentials)
      }).then(data => data.json()).catch(err => Swal.fire({
              title: 'Error confirming payment!',
              icon: 'error',
              confirmButtonText: 'Try again',
      }).then((result) => {
          if(result.isConfirmed){
           window.location.href = "/confirm";
          }
      })
      );
}
const ConfirmPay = () => {
    const config = JSON.parse(sessionStorage.getItem('config'));
	const copayath  = sessionStorage.getItem('copayath');
    if(copayath != null || copayath != undefined){
        const amount = config.amount;
        const email = config.email;
        const reference = config.reference;
        const response = updatePayment({
            amount,
            reference,
            email
        });
        if(response){
            sessionStorage.removeItem('copayath');
            sessionStorage.removeItem('config');
            Swal.fire({
                title: 'Payment confirmed!',
                text: "You will be redirected to our contact page shortly...",
                icon: 'success',
                showConfirmButton: false
            });
            function redirectco(){
                window.location.href = "/contact"
            }
            setTimeout(redirectco, 5000);
        }else{
            window.location.href = "/confirm";
        }
    }else{
        window.location.href = "/register";
    }
    return (
        <div></div>
    )
   }
export default ConfirmPay; 