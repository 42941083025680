import Logo from '../assets/images/logo2.png';
import React, {useEffect} from 'react'
import $ from 'jquery'
import { Link } from 'react-router-dom';

const Header = () => {
    useEffect(()=> {
        $(function(){

            $('.primary-menu').find('li a').each(function () {
                if ($(this).next().length > 0) {
                  $(this).parent('li').append('<span class="menu-trigger"><i class="fal fa-angle-down"></i></span>');
                }
              });
            
                // expands the dropdown menu on each click
                $('.primary-menu').find('li .menu-trigger').on('click', function (e) {
                  e.preventDefault();
                  $(this).toggleClass('open').parent('li').children('ul').stop(true, true).slideToggle(350);
                  $(this).find("i").toggleClass("fa-angle-up fa-angle-down");
                });

                function breakpointCheck() {
                    var windoWidth = window.innerWidth;
                
                    if (windoWidth <= 991) {
                      $('.header-navbar').addClass('mobile-menu');
                    } else {
                      $('.header-navbar').removeClass('mobile-menu');
                    }
                  }
                
                  breakpointCheck();
                  $(window).on('resize', function () {
                    breakpointCheck();
                  });
                
                
                  $('.nav-toggler').on('click', function (e) {
                    $('.site-navbar').toggleClass('menu-on');
                    e.preventDefault();
                  });
                
                  // Close menu on toggler click
                  $('.nav-close').on('click', function (e) {
                    $('.site-navbar').removeClass('menu-on');
                    e.preventDefault();
                  });
                
                
                  // Offcanvas Info menu
                
                  $('.offcanvas-icon').on('click', function (e) {
                    $('.offcanvas-info').toggleClass('offcanvas-on');
                    e.preventDefault();
                  });
                
                  // Close menu on toggler click
                  $('.info-close').on('click', function (e) {
                    $('.offcanvas-info').removeClass('offcanvas-on');
                    e.preventDefault();
                  });
                
                 //Search Box addClass removeClass
                 $('.header_search_btn > a').on('click', function(){
                  $('.page_search_box').addClass('active')
                  });
                  $('.search_close > i').on('click', function(){
                      $('.page_search_box').removeClass('active')
                  });
                
                
                
                
                
                  /* ---------------------------------------------
                      Sticky Fixed Menu
                  --------------------------------------------- */
                
                  $(window).scroll(function () {
                    var window_top = $(window).scrollTop() + 1;
                
                    if (window_top > 50) {
                      $('.fixed-btm-top').addClass('reveal');
                    } else {
                      $('.fixed-btm-top').removeClass('reveal');
                    }
                  });
                
                
                  /* ---------------------------------------------
                     Bottom To Top hide
                  --------------------------------------------- */
                
                  $(window).scroll(function () {
                    var window_top = $(window).scrollTop() + 1;
                
                    if (window_top > 50) {
                      $('.fixed-btm-top').addClass('reveal');
                    } else {
                      $('.fixed-btm-top').removeClass('reveal');
                    }
                  });
                
                  //  Sticky Menu
                
                $(window).scroll(function () {
                    var window_top = $(window).scrollTop() + 1;
                    if (window_top > 50) {
                      $('.navbar-sticky').addClass('menu_fixed animated fadeInDown');
                    } else {
                      $('.navbar-sticky').removeClass('menu_fixed animated fadeInDown');
                    }
                  });

    })
});
    return(
        <header className="header-style-2" id='top-header'> 
            <div className="header-navbar menu-2 navbar-sticky">
                <div className="container-fluid container-padding">
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="site-logo">
                            <Link to="/home">
                                <img src={Logo} alt="" className="img-fluid" />
                            </Link>
                        </div>

                        <div className="offcanvas-icon d-block d-lg-none">
                            <Link to="#" className="nav-toggler"><i className="fal fa-bars"></i></Link> 
                        </div>
        
                        <nav className="site-navbar ms-auto">
                            <ul className="primary-menu">
                                <li className="current">
                                    <Link to="/home">Home</Link>
                                </li>
                                <li>
                                    <Link to="/courses">Courses</Link>
                                </li>
                                <li>
                                    <Link to="/contact">Contact</Link>
                                </li>
                            </ul>

                            <Link to="#" className="nav-close"><i className="fal fa-times"></i></Link>
                        </nav>

                        <div className="header-btn border-left-0 ms-3 d-none d-lg-block">
                            <Link to="/register" className="btn btn-grey-outline btn-sm-2 rounded"><i className="fal fa-user me-2"></i>Register now</Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )

    }
export default Header;